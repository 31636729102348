import React from "react";
import { Col, Row } from "antd";
import { Link } from "gatsby";
import { PageContentEmployerScreen } from "../../../components/PageContent/PageContent";
import { NewBanner } from "../../../components/Solutions/newBanner";
import BreadcrumbSec from "./Breadcrumb";
import Sidebar from "./sidebar";
import DedicatedImg from "../../../images/solutions/InternationalEngagement/Dedicated-Centers.webp";
import InfrastructureImg from "../../../images/solutions/InternationalEngagement/Infrastructure-Services.webp";
import FinanceImg from "../../../images/solutions/InternationalEngagement/Finance-Outsourcing.webp";
import OffshoreImg from "../../../images/solutions/InternationalEngagement/Offshore-Resources.webp";
import banImg from "../../../images/solutions/banner/International-Engagement.webp";
import Stories from "../../../components/employerHomePage/stories";

const InternationalEngagement = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <NewBanner BannerImg={banImg} title={"International Engagement"} />
      <BreadcrumbSec current={"International Engagement"} />

      <section className="mainSolutionSection px-3 px-md-6">
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} xl={16} className="float-right">
            <div className="box">
              <div className="heading">
                <h1>International Engagement</h1>
                <span className="separator"></span>
              </div>
              <div className="d-flex align-items-center pb-3">
                <h2 className="p-0 m-0 text-bold">
                  Comprehensive services portfolio for international clients{" "}
                </h2>
              </div>
              <p className={""}>
                Talent pools aren’t restricted to geographical boundaries. At
                Adecco, we provide international outsourcing services like
                building offshore delivery centers along with recruitment
                services. We understand the challenges involved in mobilizing
                the global expatriate workforce. So, we work closely with our
                clients and provide tailored business solutions for their unique
                prerequisite.{" "}
              </p>
              <p>
                We offer end-to-end flexible engagement models to help global
                clients who do not have a presence in India with administrative
                and infrastructure setup, finance and taxation and, talent
                supply for the entire value chain navigating formal obligations,
                and legal and regulatory compliance requirements. Our
                International Engagement model enables global organizations to
                build a presence in India, supporting their workforce
                requirements and helping in building strategic capacity and
                scale. During this transition period, employee mobility,
                repatriation, sourcing, infrastructure, payroll & compliance,
                office search will be key areas on which we focus.{" "}
              </p>
              <p className={"text-danger text-bold"}>
                Our extensive global expertise, local knowledge, and connected
                approach provide international companies with the convenience,
                simplicity, and efficiency of working with a single trusted
                workforce solutions partner for all their needs.{" "}
              </p>
              <p>
                Through our on-demand tracking and reporting, we provide
                meaningful data analysis to drive smart business decisions. In
                short, professional organizations can rely on us to navigate
                organizational changes and optimize and reduce operational costs
                by outsourcing and allowing scaling up or down based on their
                business requirements and in sync with their operation time zone
                with confidence, ease, and superior results. Our dedicated team
                of industry experts lowers the burden before, during, and after
                service delivery with innovative, customized solutions that
                ensure a high return on investment and a seamless experience at
                every stage of engagement. We take strategic lead and build
                stronger relationship with our clients through our International
                outsourcing services. This, together with our unrivalled
                national coverage and global presence, is how we keep one step
                ahead of the competition.{" "}
              </p>
              <div className="d-flex align-items-center">
                <h2 className="p-0 m-0">Global SMEs | Local Operations </h2>
              </div>
              <div className="d-flex align-items-center">
                <img loading="lazy"  src={OffshoreImg} alt="OffshoreImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Offshore Resources </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Expatriate mobilization </li>
                <li>VISA process/ FRRO/Travel/Insurance </li>
                <li>Local talent employment and deployment </li>
                <li>
                  HRMS – Recruitment, Onboarding, attendance, Payroll,
                  Compliance & Taxation service.
                </li>
                <li>Payroll outsourcing & statutory compliance </li>
                <li>Corporate wellness program </li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={InfrastructureImg} alt="InfrastructureImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Infrastructure Services:</h2>
              </div>
              <ul className={"pl-7"}>
                <li>IT Assets -procurement / rental. </li>
                <li>
                  Office seating facility / new office search & office leasing.
                </li>
                <li>Admin services – Housekeeping, security & maintenance.</li>
                <li>Vehicle leasing </li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={FinanceImg} alt="FinanceImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Finance Outsourcing: </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Book-keeping </li>
                <li>Taxation & filing</li>
                <li>Audit & Company secretarial services. </li>
              </ul>

              <div className="d-flex align-items-center">
                <img loading="lazy"  src={DedicatedImg} alt="DedicatedImg" width={48} className="mr-2" />
                <h2 className="p-0 m-0">Dedicated Centres </h2>
              </div>
              <ul className={"pl-7"}>
                <li>Global Capability Centre (GCC’s) </li>
                <li>Development Centre </li>
                <li>Shared Services Centre </li>
                <li>Turnkey Offshore Centre </li>
              </ul>

              <div className="text-center">
                <Link
                  to="/employer/business-enquiry/"
                  state={{ interested: "International Engagement" }}
                >
                  Business Enquiry
                </Link>
              </div>
            </div>
          </Col>
          <Sidebar />
        </Row>
        <Stories heading="Case Study" isReadMore={false} />
      </section>
    </PageContentEmployerScreen>
  )
}

export default InternationalEngagement
